body {
    background-color: #fefefe;
}

.loader {
    position: absolute;
    top: calc(50% - 8.333333%);
    left: calc(50% - 8.333333%);
    min-height: 24px;
    min-width: 24px;
    background: transparent;
    border: 2px solid rgba(90, 50, 147, 0.5);
    border-radius: 50%;
    line-height: inherit;
    box-shadow: 0 0 10px rgba(90, 50, 147, 0.5);

    &:before {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 2px solid transparent;
        border-top: 2px solid rgb(120, 77, 181);
        border-right: 2px solid rgb(120, 77, 181);
        border-radius: 50%;
        animation: animateCircle 0.5s linear infinite;
    }

    > span {
        margin-top: 25% !important;

        > span {
            padding-top: 50% !important;
        }
    }

    .icon {
        position: absolute;
        height: 50%;
        width: 50%;
        display: block;
        mix-blend-mode: darken;
    }

    .ball {
        display: block;
        position: absolute;
        top: calc(50% - 2px);
        left: 50%;
        width: 50%;
        height: 4px;
        background: transparent;
        transform-origin: left;
        animation: animateBall 0.5s linear infinite;

        &:before {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: rgb(0, 0, 0);
            top: -2px;
            right: -3px;
            box-shadow: 0 0 10px rgb(120, 77, 181);
        }
    }
}

.ul {
    list-style-type: disc;
}

@keyframes animateCircle {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animateBall {
    0% {
        transform: rotate(45deg);
    }

    100% {
        transform: rotate(405deg);
    }
}

@media (min-width: 768px) {
    .md\:pl-64 {
        padding-left: 16rem !important;
    }
    .md\:pl-16 {
        padding-left: 4rem !important;
    }
}
